import React, { useState, useEffect } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import _ from "lodash";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Input,
  SimpleGrid,
  Spinner,
  Switch,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { messages } from "consts";
import { SyncSelect, InputChips } from "components";
import { validationErrorHandler } from "lib";
import { useApiGet } from "hooks";
import * as yup from "yup";
import { MdSave, MdChevronLeft } from "react-icons/md";
import { BiUser } from "react-icons/bi";
import api from "lib/api";
import LinesContext from "./context";
import ItinerariesList from "./itineraries";
import TimesheetsList from "./timesheets";

export const LinesDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet(`/lines/${_id}`);
  const [zones] = useApiGet("/zones");
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setFormData(data ?? { itineraries: [], zones: [], periods: [], active: true });
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        code: yup.string().required(messages.error.required),
        name: yup.string().required(messages.error.required),
        periods: yup.array().min(1, messages.error.required),
        zones: yup.array().min(1, messages.error.required),
      });
      const data = { ...formData, zones: _.map(formData.zones, (o) => o._id) };
      await schema.validate(data);
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      if (_id) {
        await api.put(`/lines/${_id}`, data);
        setFormData((state) => {
          state.__v++;
          return state;
        });
      } else {
        const saved = await api.post("/lines", data);
        navigate(`/lines/details/${saved._id}`, { replace: true });
        refreshData();
      }
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      validationErrorHandler(error, setFormErrors);
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <LinesContext.Provider value={{ formData, formErrors, setFormData, zones }}>
      <Box>
        <HStack mb="20px" justify="space-between">
          <Breadcrumb fontWeight="medium" fontSize="sm">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/home">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/lines">
                Linhas
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.name ?? "Novo"}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <HStack spacing={6}>
            <Button size="sm" leftIcon={<Icon as={BiUser} />} isLoading={loadingData}>
              {data?.subscribers?.toLocaleString() ?? 0} assinantes
            </Button>
            <HStack>
              <Text fontSize="sm">Ativo?</Text>
              <Switch isChecked={formData.active} onChange={() => setFormData((state) => ({ ...state, active: !state.active }))} />
            </HStack>
          </HStack>
        </HStack>

        <form onSubmit={handleSubmit}>
          <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
            <FormControl isRequired={true} isInvalid={formErrors.code}>
              <FormLabel>Código</FormLabel>
              <Input value={formData.code ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, code: target.value }))} />
              <FormErrorMessage>{formErrors.code}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={formErrors.qrCodeLineCode}>
              <FormLabel>Código da linha no QRCode</FormLabel>
              <Input
                value={formData.qrCodeLineCode ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, qrCodeLineCode: target.value }))}
              />
              <FormErrorMessage>{formErrors.qrCodeLineCode}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired={true} isInvalid={formErrors.name}>
              <FormLabel>Nome</FormLabel>
              <Input value={formData.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
              <FormErrorMessage>{formErrors.name}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
            <FormControl isInvalid={formErrors.periods}>
              <HStack justify="space-between" mb="0.5rem">
                <HStack spacing={1}>
                  <FormLabel m={0}>Períodos</FormLabel>
                  <Text color="red">*</Text>
                </HStack>
                <Text fontSize="sm">(ex: segunda a sexta)</Text>
              </HStack>
              <InputChips
                data={formData.periods}
                onChange={(periods) => setFormData((state) => ({ ...state, periods }))}
                isDisabled={_id}
              />
              <FormErrorMessage>{formErrors.periods}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired={true} isInvalid={formErrors.zones}>
              <FormLabel>Zonas</FormLabel>
              <SyncSelect
                isMulti
                placeholder="Selecione as zonas"
                value={formData.zones ?? []}
                options={zones?.data ?? []}
                getOptionValue={(o) => o._id}
                getOptionLabel={(o) => o.name}
                onChange={(zones) => setFormData((state) => ({ ...state, zones }))}
                isDisabled={_id}
              />
              <FormErrorMessage>{formErrors.zones}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid mb={4}>
            <FormControl isInvalid={formErrors.ura}>
              <FormLabel m={0}>Códigos da URA</FormLabel>
              <InputChips
                data={formData.ura}
                onChange={(ura) => setFormData((state) => ({ ...state, ura: _.map(ura, (item) => parseInt(item)) }))}
              />
              <FormHelperText>
                Os códigos acima representam a linha na URA. Para linhas com variações alfanuméricas cadastre mais de um código.
              </FormHelperText>
              <FormErrorMessage>{formErrors.ura}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid spacing={4} mb={4}>
            <FormControl>
              <FormLabel>Observações</FormLabel>
              <Textarea
                value={formData.comments ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
              />
            </FormControl>
          </SimpleGrid>

          <ItinerariesList />

          {_id && <TimesheetsList />}

          <HStack justify="flex-end" spacing={4} mt={6}>
            <Button as={RouterLink} to="/lines" leftIcon={<Icon as={MdChevronLeft} />}>
              Voltar
            </Button>
            <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData}>
              Salvar
            </Button>
          </HStack>
        </form>
      </Box>
    </LinesContext.Provider>
  );
};
