import React, { useState, useEffect } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Image,
  Input,
  SimpleGrid,
  Spinner,
  Switch,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { messages } from "consts";
import { validationErrorHandler } from "lib";
import { useApiGet } from "hooks";
import * as yup from "yup";
import { ImageLibrary } from "containers";
import { MdSave, MdChevronLeft, MdAttachFile } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import api from "lib/api";

export const GreetingsDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet(`/greetings/${_id}`);
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [showImageLibrary, setShowImageLibrary] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setFormData(data ?? { active: true });
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        body: yup.string().required(messages.error.required),
      });
      await schema.validate(formData);
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      if (_id) {
        await api.put(`/greetings/${_id}`, data);
        setFormData((state) => {
          state.__v++;
          return state;
        });
      } else {
        const saved = await api.post("/greetings", data);
        navigate(`/greetings/details/${saved._id}`, { replace: true });
        refreshData();
      }
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      validationErrorHandler(error, setFormErrors);
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <>
      <Box>
        <HStack marginBottom="20px" justify="space-between">
          <Breadcrumb fontWeight="medium" fontSize="sm">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/home">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/greetings">
                Saudações
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.title ?? "Novo"}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <HStack>
            <Text fontSize="sm">Ativo?</Text>
            <Switch isChecked={formData.active} onChange={() => setFormData((state) => ({ ...state, active: !state.active }))} />
          </HStack>
        </HStack>

        <form onSubmit={handleSubmit}>
          <VStack mb="20px">
            {formData.img && (
              <Box width="300px">
                <Image src={formData.img?.transforms?.original?.location} alt={formData.img?.name} borderRadius="lg" />
              </Box>
            )}
            <HStack>
              <Button colorScheme="main" onClick={() => setShowImageLibrary(true)} leftIcon={<Icon as={MdAttachFile} />}>
                Selecionar imagem
              </Button>
              {formData.img && (
                <Button onClick={() => setFormData((state) => ({ ...state, img: null }))} leftIcon={<Icon as={IoMdTrash} />}>
                  Remover imagem
                </Button>
              )}
            </HStack>
          </VStack>
          <SimpleGrid spacing={4} mb={4}>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel>Título</FormLabel>
              <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid spacing={4} mb={4}>
            <FormControl isRequired={true} isInvalid={formErrors.body}>
              <FormLabel>Mensagem</FormLabel>
              <Textarea
                value={formData.body ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, body: target.value }))}
                rows={10}
              />
              <FormErrorMessage>{formErrors.body}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <HStack justify="flex-end" spacing={4} mt={6}>
            <Button as={RouterLink} to="/greetings" leftIcon={<Icon as={MdChevronLeft} />}>
              Voltar
            </Button>
            <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData}>
              Salvar
            </Button>
          </HStack>
        </form>
      </Box>
      <ImageLibrary
        isOpen={showImageLibrary}
        onClose={() => setShowImageLibrary(false)}
        onFinish={([img]) => setFormData((state) => ({ ...state, img }))}
      />
    </>
  );
};
