import React from "react";

const Logo = ({ width = 100, height = 40, color = "#fff" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 486.525 115.927">
      <g transform="translate(-409.975 -413.443)">
        <path
          d="M374.553,453.722a47.886,47.886,0,0,1-10.092,16.527,47.031,47.031,0,0,1-15.877,10.971,52.525,52.525,0,0,1-20.885,3.991,62.949,62.949,0,0,1-23.229-4.149,53.8,53.8,0,0,1-18.229-11.671,53.146,53.146,0,0,1-11.969-18.016,60.572,60.572,0,0,1-4.3-23.184,61.964,61.964,0,0,1,4.3-23.269,56.124,56.124,0,0,1,11.969-18.565,54.23,54.23,0,0,1,18.3-12.217,60.23,60.23,0,0,1,23.156-4.388,55.2,55.2,0,0,1,18.7,3.214,73.429,73.429,0,0,1,16.971,8.7L353.512,401.4q-3.283-2.038-6.412-3.84a62.977,62.977,0,0,0-6.416-3.213,43.577,43.577,0,0,0-6.805-2.27,32.666,32.666,0,0,0-7.746-.865,28.46,28.46,0,0,0-13.452,3.055,30.674,30.674,0,0,0-9.778,8.069,35.04,35.04,0,0,0-6.021,11.6,47.462,47.462,0,0,0,0,27.492,33.467,33.467,0,0,0,6.1,11.44A29.545,29.545,0,0,0,313,460.7a30.989,30.989,0,0,0,13.761,2.9,24.4,24.4,0,0,0,9.935-1.959,23.4,23.4,0,0,0,7.668-5.323,28.737,28.737,0,0,0,5.238-7.753,35.21,35.21,0,0,0,2.819-9.243H337.865a10.991,10.991,0,0,1-7.978-3.212,10.641,10.641,0,0,1-3.28-7.91,10.333,10.333,0,0,1,3.359-7.759,10.884,10.884,0,0,1,7.746-3.212h26.6q7.975,0,10.869,4.077t2.894,11.591a59.832,59.832,0,0,1-3.519,20.834Z"
          transform="translate(140 44)"
          fill={color}
        />
        <path
          d="M468.5,386.986l-35.2,88.825a18.1,18.1,0,0,1-4.461,7.052q-2.741,2.5-7.9,2.508a10.015,10.015,0,0,1-7.431-2.744,21.037,21.037,0,0,1-4.459-6.815l-39.264-97.6,23.149-8.768,28.315,77.7,24.093-68.777a14.048,14.048,0,0,1,4.46-6.187,11.105,11.105,0,0,1,7.121-2.428,12.319,12.319,0,0,1,4.769.944,13.838,13.838,0,0,1,4.066,2.579,11.871,11.871,0,0,1,2.819,3.918,11.638,11.638,0,0,1,1.016,4.777,13.2,13.2,0,0,1-1.1,5.015Z"
          transform="translate(140 44)"
          fill={color}
        />
        <path
          d="M503.222,415.5h11.107a15.186,15.186,0,0,0,4.849-.785,13,13,0,0,0,7.273-6.03,9.653,9.653,0,0,0,1.173-4.776,9.448,9.448,0,0,0-1.094-4.545,12.421,12.421,0,0,0-2.813-3.524,13.209,13.209,0,0,0-3.992-2.349,12.953,12.953,0,0,0-4.612-.863h-11.89Zm-.159,46.689h19.243a13.55,13.55,0,0,0,5.163-1.022,14.5,14.5,0,0,0,4.382-2.738,12.854,12.854,0,0,0,2.965-4.077,12.7,12.7,0,0,0,0-10.338,13.746,13.746,0,0,0-2.965-4.15,14.181,14.181,0,0,0-4.308-2.824,13.068,13.068,0,0,0-5.08-1.015h-19.4Zm55.068,1.8a31.907,31.907,0,0,1-7.668,10.575,35.04,35.04,0,0,1-11.338,6.815,39.228,39.228,0,0,1-13.848,2.429H488.2q-6.417,0-10.013.153V383.692a12.206,12.206,0,0,1,3.676-9.085,12.586,12.586,0,0,1,9.157-3.6h25.028a42.137,42.137,0,0,1,13.295,2.118,34.5,34.5,0,0,1,11.344,6.188,30.815,30.815,0,0,1,7.905,9.943,29,29,0,0,1,2.971,13.24,26.175,26.175,0,0,1-2.74,11.986,22.589,22.589,0,0,1-8.057,8.848,27.937,27.937,0,0,1,3.518,1.252,25.647,25.647,0,0,1,3.524,1.723,25.08,25.08,0,0,1,9.7,9.954,28.635,28.635,0,0,1,3.439,13.862,33.1,33.1,0,0,1-2.813,13.866Z"
          transform="translate(140 44)"
          fill={color}
        />
        <path
          d="M664.507,458.11a42.229,42.229,0,0,1-10.4,14.646A45.843,45.843,0,0,1,638.386,482a62.085,62.085,0,0,1-39.422,0,45.787,45.787,0,0,1-15.725-9.243,42.244,42.244,0,0,1-10.4-14.646,48.551,48.551,0,0,1-3.757-19.581V370.7h24.877v68.459q0,11.281,6.726,18.016t17.992,6.736q11.265,0,17.991-6.736t6.726-18.016V382.288a12.211,12.211,0,0,1,3.6-8.854,11.787,11.787,0,0,1,8.762-3.681,11.932,11.932,0,0,1,8.841,3.76,12.367,12.367,0,0,1,3.67,8.933v56.082a48.551,48.551,0,0,1-3.755,19.581Z"
          transform="translate(140 44)"
          fill={color}
        />
        <path
          d="M754.538,458.895a37.812,37.812,0,0,1-5.4,9.87,40.009,40.009,0,0,1-15.567,12.376,47.73,47.73,0,0,1-19.632,4.07,56.076,56.076,0,0,1-9.7-1.015q-5.633-1.023-11.575-2.586t-11.5-3.444a74.1,74.1,0,0,1-9.3-3.76,22.389,22.389,0,0,1,.857-3.529q.7-2.265,1.41-4.777t1.4-4.777c.474-1.514.808-2.689,1.021-3.522a22.889,22.889,0,0,0,.7-2.2,20.161,20.161,0,0,1,.7-2.19,1.271,1.271,0,0,1,.31-.943v-.158a32.757,32.757,0,0,0,3.05,1.259q2.115.786,4.539,1.642t4.77,1.723c1.568.572,2.868,1.016,3.913,1.332q5.159,1.881,10.086,3.365a34,34,0,0,0,9.935,1.491,18.194,18.194,0,0,0,5.949-1.015,17.662,17.662,0,0,0,5.317-2.9,14.343,14.343,0,0,0,3.755-4.539,12.248,12.248,0,0,0,1.41-5.8,5.648,5.648,0,0,0-1.489-3.76,16.8,16.8,0,0,0-3.518-3.055,32.291,32.291,0,0,0-4.3-2.349c-1.513-.675-2.795-1.229-3.834-1.642q-6.416-2.511-13.69-4.7a66.665,66.665,0,0,1-13.532-5.721,32.1,32.1,0,0,1-10.323-9.085q-4.074-5.558-4.071-14.8a28.381,28.381,0,0,1,3.676-14.486,35.464,35.464,0,0,1,9.546-10.734,41.815,41.815,0,0,1,13.216-6.578,51.136,51.136,0,0,1,14.71-2.2,61.547,61.547,0,0,1,9.23.864,47.133,47.133,0,0,1,10.481,2.9,26.71,26.71,0,0,1,8.525,5.4,10.964,10.964,0,0,1,3.518,8.227,10.794,10.794,0,0,1-2.892,7.441,9.379,9.379,0,0,1-7.273,3.214,17.079,17.079,0,0,1-5.712-1.022q-2.9-1.013-6.022-2.191t-6.5-2.2a24.558,24.558,0,0,0-7.115-1.015,15.471,15.471,0,0,0-4.223.626,12.361,12.361,0,0,0-3.992,1.96,12.176,12.176,0,0,0-2.971,3.134,7.416,7.416,0,0,0-1.173,4.15,7.66,7.66,0,0,0,1.482,4.776,12.817,12.817,0,0,0,3.755,3.293,29.618,29.618,0,0,0,4.849,2.269c1.726.627,3.311,1.156,4.776,1.569q6.571,2.038,13.684,4.545a62.005,62.005,0,0,1,13.07,6.341,33.435,33.435,0,0,1,9.771,9.558q3.837,5.724,3.84,14.178a31.949,31.949,0,0,1-1.962,11.123Z"
          transform="translate(140 44)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Logo;
