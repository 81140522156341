module.exports = {
  firebase: {
    apiKey: "AIzaSyD2K9Z_xZDM0YImNUYCMoAu0KYGmJhT1eQ",
    authDomain: "busapp-gvbus.firebaseapp.com",
    projectId: "busapp-gvbus",
    storageBucket: "busapp-gvbus.appspot.com",
    messagingSenderId: "1088177766171",
    appId: "1:1088177766171:web:fbda5fc6ab44cf83e2ddbe",
  },
  theme: {
    colors: {
      main: {
        50: "#F2FAEB",
        100: "#EEFAD5",
        200: "#DBF5AC",
        300: "#B9E37E",
        400: "#94C858",
        500: "#65A42A",
        600: "#4E8D1E",
        700: "#3A7615",
        800: "#285F0D",
        900: "#1B4E08",
      },
    },
  },
};
