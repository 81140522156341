import React, { useState, useEffect } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import _ from "lodash";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Image,
  Input,
  SimpleGrid,
  Spinner,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { messages } from "consts";
import { validationErrorHandler } from "lib";
import { Card, CardHeader, CardTitle, CardSubTitle, CardBody, InputChips, SyncSelect } from "components";
import { useApiGet } from "hooks";
import * as yup from "yup";
import { ImageLibrary } from "containers";
import { MdSave, MdChevronLeft, MdAttachFile } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import api from "lib/api";

const formatFilters = (filters = {}) => ({
  phones: _.map(filters.phones, (phone) => phone.replace(/\D/g, "")),
  lines: _.map(filters.lines, "value"),
  exceptContactLists: _.map(filters.exceptContactLists, "value"),
});

export const NotificationsDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [recipients, setRecipients] = useState({});
  const [loadingRecipients, setLoadingRecipients] = useState(false);
  const [data, loadingData, refreshData] = useApiGet(`/notifications/${_id}`);
  const [lines, loadingLines] = useApiGet("/lines", { sort: { code: "asc" } });
  const [contactLists, loadingContactLists] = useApiGet("/contact-lists", { sort: { title: "asc" } });
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [showImageLibrary, setShowImageLibrary] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const fetchRecipients = async () => {
      try {
        setLoadingRecipients(true);
        const response = await api.get("/notifications/recipients", { params: { query: formatFilters(formData.filters) } });
        setRecipients(response);
      } finally {
        setLoadingRecipients(false);
      }
    };
    !_id && fetchRecipients();
  }, [_id, formData.filters]);

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        body: yup.string().required(messages.error.required),
      });
      const data = { ...formData, filters: formatFilters(formData.filters) };
      await schema.validate(data);
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      if (_id) {
        await api.put(`/notifications/${_id}`, data);
        setFormData((state) => {
          state.__v++;
          return state;
        });
      } else {
        const saved = await api.post("/notifications", data);
        navigate(`/notifications/details/${saved._id}`, { replace: true });
        refreshData();
      }
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      validationErrorHandler(error, setFormErrors);
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <>
      <Box>
        <HStack marginBottom="20px" justify="space-between">
          <Breadcrumb fontWeight="medium" fontSize="sm">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/home">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/notifications">
                Notificações
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>
                {loadingData || loadingLines || loadingContactLists ? <Spinner size="xs" /> : data?.title ?? "Novo"}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>

        {_id && (
          <>
            <Card>
              <CardHeader>
                <CardTitle>Entrega</CardTitle>
                <CardSubTitle>Informações referentes à entrega da notificação</CardSubTitle>
              </CardHeader>
              <CardBody>
                <SimpleGrid columns={[1, 4]} spacing={4} mb={4}>
                  <Box>
                    <Text fontSize="xs" fontWeight="bold">
                      In App Recipientes
                    </Text>
                    <Text>{formData.delivery?.inAppRecipientsCount?.toLocaleString() ?? 0}</Text>
                  </Box>
                  <Box>
                    <Text fontSize="xs" fontWeight="bold">
                      Push Recipientes
                    </Text>
                    <Text>{formData.delivery?.pushRecipientsCount?.toLocaleString() ?? 0}</Text>
                  </Box>
                  <Box>
                    <Text fontSize="xs" fontWeight="bold">
                      Push Sucessos
                    </Text>
                    <Text>{formData.delivery?.pushSuccessCount?.toLocaleString() ?? 0}</Text>
                  </Box>
                  <Box>
                    <Text fontSize="xs" fontWeight="bold">
                      Push Falhas
                    </Text>
                    <Text>{formData.delivery?.pushFailureCount?.toLocaleString() ?? 0}</Text>
                  </Box>
                </SimpleGrid>
                <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
                  <Box>
                    <Text fontSize="xs" fontWeight="bold">
                      Números de telefone
                    </Text>
                    <Text fontSize="xs">{_.join(formData.filters?.phones, ", ") || "-"}</Text>
                  </Box>
                  <Box>
                    <Text fontSize="xs" fontWeight="bold">
                      Linhas
                    </Text>
                    <Text fontSize="xs">{_.map(formData.filters?.lines, "name").join(", ") || "-"}</Text>
                  </Box>
                </SimpleGrid>
                <SimpleGrid columns={[1]} spacing={4}>
                  <Box>
                    <Text fontSize="xs" fontWeight="bold">
                      Exceto listas de contato
                    </Text>
                    <Text fontSize="xs">{_.map(formData.filters?.exceptContactLists, "title").join(", ") || "-"}</Text>
                  </Box>
                </SimpleGrid>
              </CardBody>
            </Card>
          </>
        )}

        <form onSubmit={handleSubmit}>
          {!_id && (
            <Card>
              <CardHeader>
                <HStack>
                  {loadingRecipients && <Spinner size="xs" />}
                  <CardTitle>Filtros</CardTitle>
                </HStack>
                <CardSubTitle>Informe os filtros que deverão ser utilizados para a seleção dos usuários.</CardSubTitle>
              </CardHeader>
              <CardBody>
                <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
                  <Box>
                    <Text fontSize="xs" fontWeight="bold">
                      In App Recipientes
                    </Text>
                    <Text>{recipients.inAppRecipientsCount?.toLocaleString() ?? 0}</Text>
                  </Box>
                  <Box>
                    <Text fontSize="xs" fontWeight="bold">
                      Push Recipientes
                    </Text>
                    <Text>{recipients.pushRecipientsCount?.toLocaleString() ?? 0}</Text>
                  </Box>
                </SimpleGrid>
                <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
                  <FormControl isInvalid={formErrors["filters.phones"]}>
                    <FormLabel>Números de telefone</FormLabel>
                    <InputChips
                      as={InputMask}
                      mask="+55 (99) 9 9999-9999"
                      data={formData.filters?.phones}
                      onChange={(phones) => setFormData((state) => ({ ...state, filters: { ...state.filters, phones } }))}
                    />
                    <FormErrorMessage>{formErrors["filters.phones"]}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={formErrors["filters.lines"]}>
                    <FormLabel>Linhas</FormLabel>
                    <SyncSelect
                      isMulti
                      placeholder="Selecione as linhas"
                      value={formData.filters?.lines ?? []}
                      onChange={(lines) => setFormData((state) => ({ ...state, filters: { ...state.filters, lines } }))}
                      options={_.map(lines?.data, (o) => ({ value: o._id, label: `${o.code} • ${o.name}` }))}
                    />
                    <FormErrorMessage>{formErrors["filters.lines"]}</FormErrorMessage>
                  </FormControl>
                </SimpleGrid>

                <SimpleGrid columns={[1]} spacing={4}>
                  <FormControl isInvalid={formErrors["filters.exceptContactLists"]}>
                    <FormLabel>Exceto listas de contato</FormLabel>
                    <SyncSelect
                      isMulti
                      placeholder="Selecione as listas de contato"
                      value={formData.filters?.exceptContactLists ?? []}
                      onChange={(exceptContactLists) =>
                        setFormData((state) => ({ ...state, filters: { ...state.filters, exceptContactLists } }))
                      }
                      options={_.map(contactLists?.data, (o) => ({ value: o._id, label: o.title }))}
                    />
                    <FormErrorMessage>{formErrors["filters.exceptContactLists"]}</FormErrorMessage>
                  </FormControl>
                </SimpleGrid>
              </CardBody>
            </Card>
          )}

          <Card>
            <CardHeader>
              <CardTitle>Notificação</CardTitle>
              <CardSubTitle>Informe os dados referentes à notificação que deverá ser enviada.</CardSubTitle>
            </CardHeader>
            <CardBody>
              <VStack mb="20px">
                {formData.img && (
                  <Box width="300px">
                    <Image src={formData.img?.transforms?.original?.location} alt={formData.img?.name} borderRadius="lg" />
                  </Box>
                )}
                <HStack>
                  <Button colorScheme="main" onClick={() => setShowImageLibrary(true)} leftIcon={<Icon as={MdAttachFile} />}>
                    Selecionar imagem
                  </Button>
                  {formData.img && (
                    <Button onClick={() => setFormData((state) => ({ ...state, img: null }))} leftIcon={<Icon as={IoMdTrash} />}>
                      Remover imagem
                    </Button>
                  )}
                </HStack>
              </VStack>

              {formData.createdBy && (
                <SimpleGrid spacing={4} mb={4}>
                  <FormControl isRequired={true} isInvalid={formErrors.createdBy}>
                    <FormLabel>Criada por</FormLabel>
                    <Input value={formData.createdBy.name ?? ""} isDisabled={true} />
                    <FormErrorMessage>{formErrors.createdBy}</FormErrorMessage>
                  </FormControl>
                </SimpleGrid>
              )}

              <SimpleGrid spacing={4} mb={4}>
                <FormControl isRequired={true} isInvalid={formErrors.title}>
                  <FormLabel>Título</FormLabel>
                  <Input
                    value={formData.title ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.title}</FormErrorMessage>
                </FormControl>
              </SimpleGrid>

              <SimpleGrid spacing={4} mb={4}>
                <FormControl isRequired={true} isInvalid={formErrors.body}>
                  <FormLabel>Mensagem</FormLabel>
                  <Textarea
                    value={formData.body ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, body: target.value }))}
                    rows={10}
                  />
                  <FormErrorMessage>{formErrors.body}</FormErrorMessage>
                </FormControl>
              </SimpleGrid>

              <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
                <FormControl isInvalid={formErrors.actionButtonTitle}>
                  <FormLabel>Título do Botão de Ação</FormLabel>
                  <Input
                    value={formData.actionButtonTitle ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, actionButtonTitle: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.actionButtonTitle}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={formErrors.actionButtonLink}>
                  <FormLabel>Link do Botão de Ação</FormLabel>
                  <Input
                    value={formData.actionButtonLink ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, actionButtonLink: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.actionButtonLink}</FormErrorMessage>
                </FormControl>
              </SimpleGrid>
            </CardBody>
          </Card>

          <HStack justify="flex-end" spacing={4} mt={6}>
            <Button as={RouterLink} to="/notifications" leftIcon={<Icon as={MdChevronLeft} />}>
              Voltar
            </Button>
            <Button
              type="submit"
              leftIcon={<Icon as={MdSave} />}
              colorScheme="main"
              isLoading={loadingSaveData || loadingData || loadingLines || loadingContactLists}
              isDisabled={_id}
            >
              Salvar
            </Button>
          </HStack>
        </form>
      </Box>
      <ImageLibrary
        isOpen={showImageLibrary}
        onClose={() => setShowImageLibrary(false)}
        onFinish={([img]) => setFormData((state) => ({ ...state, img }))}
      />
    </>
  );
};
